import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { GETPROMOCODE } from "./queries";
import { PromoCodeType } from "../../../models/promocodes";
import { ErrorType } from "../../../models/errors";

interface PromoCodeProps {
  onApplyPromoCode: (code: PromoCodeType) => void;
  removeAppliedCode: () => void;
  isHorizontal?: boolean; // Optional prop to determine layout orientation
}

const PromoCode: React.FC<PromoCodeProps> = ({
  onApplyPromoCode,
  isHorizontal = false,
  removeAppliedCode,
}) => {
  const [promoCode, setPromoCode] = useState("");
  const [applied, setApply] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const [
    getPromoCodeQuery,
    { data: promo_code_data, loading, error: query_error },
  ] = useLazyQuery(GETPROMOCODE, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (promo_code_data && promo_code_data.promoCode) {
      onApplyPromoCode(promo_code_data.promoCode);
      setApply(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo_code_data]);

  useEffect(() => {
    if (query_error) {
      console.log("index.tsx:29 | query_error", query_error);
      const set_error: ErrorType = {
        message: query_error.graphQLErrors[0].message,
      };
      setError(set_error);
    }
  }, [query_error]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value.replace(/\s/g, ""));
  };

  const getPromoCode = () => {
    getPromoCodeQuery({ variables: { promo_code: promoCode } });
  };

  const removePromoCode = () => {
    setPromoCode("");
    setApply(false);
    removeAppliedCode();
  };

  // Conditional class for layout
  const containerClass = isHorizontal
    ? "promo-code-container-horizontal"
    : "promo-code-container";

  return (
    <div>
      <div className={containerClass}>
        <label htmlFor="promoCodeInput" className="promo-label">
          {applied ? "Applied Promo Code" : "Have a Promo Code?"}
        </label>
        <input
          id="promoCodeInput"
          className="promo-input"
          type="text"
          value={promoCode}
          onChange={handleInputChange}
          placeholder="Type promo code here"
          disabled={applied || loading}
        />
        <button
          className="promo-button"
          onClick={applied ? removePromoCode : getPromoCode}
          disabled={loading}
        >
          {loading ? (
            <i className="fal fa-spinner-third fa-spin"></i>
          ) : applied ? (
            "Remove"
          ) : (
            "Apply"
          )}
        </button>
      </div>
      {error ? (
        <small className="text-danger text-center w-100">{error.message}</small>
      ) : null}
    </div>
  );
};

export default PromoCode;
