import React from "react";
import { BooksTitlesType } from ".";
import coming_soon_img from "../../assets/coming-soon.png";

type TitleBlockProps = {
  bookTitle: BooksTitlesType;
};
const TitleBlock: React.FC<TitleBlockProps> = ({ bookTitle }) => (
  <div className="book relative">
    <div className="book-desc">
      <img
        src={bookTitle.images.cover}
        className="book-cover"
        alt={bookTitle.title}
      />
      <h5 className="book-title">{bookTitle.title}</h5>
      <p
        className="book-description"
        dangerouslySetInnerHTML={{ __html: bookTitle.description_short }}
      ></p>
    </div>
    <div className="book-bottom">
      {bookTitle.author ? (
        <p className="book-author">
          {" "}
          <i className="fad fa-book-user" aria-hidden="true"></i> Author:{" "}
          {bookTitle.author}
        </p>
      ) : null}
      <p className="book-themes">
        {bookTitle.themes?.split(",").map((item, key) => (
          <span
            className="badge badge-info mr-1"
            key={`${bookTitle.title}_badge${key}`}
          >
            {item}
          </span>
        ))}
      </p>
    </div>
    {bookTitle.coming_soon ? (
      <div style={{ position: "absolute", top: "0px", left: "0px" }}>
        <img
          src={coming_soon_img}
          className="coming_soon"
          alt="Coming This Soon"
        />
      </div>
    ) : null}
  </div>
);

export default TitleBlock;
