import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";
import copybutton from "../assets/copy-link-button.png";
// import CleverLogo from "../assets/cleverlogo.jpeg";
import SchoologyLogo from "../assets/schoology.png";

type ButtonTypes =
  | "link"
  | "primary"
  | "secondary"
  | "muted"
  | "disabled"
  | "danger"
  | "success"
  | "dark"
  | "transparent"
  | "outline-primary"
  | "outline-secondary"
  | "outline-muted"
  | "outline-disabled"
  | "outline-danger"
  | "outline-dark"
  | "outline-info"
  | "outline-success";

export type TooltipPossitionTypes =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

type ButtonTootipProps = {
  id: string;
  tooltip_txt?: string;
  link?: string | false;
  type?: ButtonTypes;
  position?: TooltipPossitionTypes;
  className?: string;
  children?: any;
  onClick?: any;
  data_value?: string;
  disabled?: boolean;
  focuseable?: boolean;
};

export const ButtonTootip: React.FC<ButtonTootipProps> = ({
  id,
  children,
  type,
  tooltip_txt,
  position = "top",
  link = false,
  focuseable = false,
  className,
  onClick,
  data_value,
  disabled,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {link ? (
        <Link
          className={`btn btn-${type} ${className} ${
            disabled ? "disabled" : ""
          } `}
          id={id}
          to={link}
        >
          {children}
        </Link>
      ) : (
        <button
          className={`btn btn-${type} ${className} ${
            focuseable ? "" : "no_focus"
          }`}
          id={id}
          onClick={onClick}
          disabled={disabled}
          data-type={data_value}
        >
          {children}
        </button>
      )}
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        target={id}
        delay={{ show: 500, hide: 200 }}
        toggle={toggle}
      >
        {tooltip_txt}
      </Tooltip>
    </>
  );
};

type CoppyButtonProps = {
  text: string;
  classes?: string;
  showimg?: boolean;
};
export const CopyButton: React.FC<CoppyButtonProps> = ({
  text,
  children,
  classes,
  showimg,
}) => {
  const [state, setState] = useState(true);
  const copy = () => {
    const el = document.createElement("textarea");
    if (!text) return false;
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setState(false);
    setTimeout(() => {
      setState(true);
    }, 1000);
  };

  return (
    <button
      type="button"
      onClick={copy}
      className={`btn-copy btn p-0 text-white ${classes}`}
    >
      {children}
      {state ? (
        showimg ? (
          <img src={copybutton} alt="coppy button" />
        ) : (
          <i className="far fa-clone ml-2" aria-hidden="true"></i>
        )
      ) : (
        <small className="ml-2">Copied!</small>
      )}
    </button>
  );
};

type ButtonGroupProps = {
  children: any;
  className?: string;
};

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  className,
}) => (
  <div
    className={`btn-group ${className}`}
    role="group"
    aria-label="Basic example"
  >
    {children}
  </div>
);

type CheckoutButtonProps = {
  txt: string;
  onClick?: any;
  checked?: boolean;
  value?: string;
  id?: string;
  type?: "button" | "submit" | "reset";
  size?: "small" | "normal" | "large";
  className?: string;
};

export const CheckoutButton: React.FC<CheckoutButtonProps> = ({
  txt,
  checked,
  value,
  id,
  onClick,
  type = "button",
  size = "normal",
  className = "btn-primary",
}) => (
  <button
    type={type}
    className={`btn ${checked ? "active" : ""} ${className} ${renderSize(
      size
    )} `}
    id={id}
    data-checked={checked}
    value={value || txt.toLocaleLowerCase().split(" ").join("_")}
    onClick={onClick}
  >
    <i
      className={`fal ${checked ? "fa-check-square" : "fa-square"} `}
      aria-hidden="true"
    ></i>{" "}
    {txt}
  </button>
);

type SsoButtonProps = {
  txt: string;
  fun: any;
  disalbed?: boolean;
  type?: "Google" | "Edlink";
};
export const SsoButton: React.FC<SsoButtonProps> = ({
  txt,
  fun,
  disalbed,
  type = "Google",
}) => {
  const google_icon =
    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=";
  const google_classroom_icon =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEdElEQVR4nO2YW0ybZRjHq1FTztACHYWCWzaHzumMBk+Zxrm1EyduTtwcBycoxkW98NI7D3ObEzdwA7oDE9kROuZiYuIVjEMptKV0pb3eLjxcrIvA2q/nv3mrIb4vfC98HTXfRf/Jk5CUtr/f2+f7vvd9FIpUUkklFVkFLr0Obv1leAyz8OgB9xbAvRmxqU2ITb2E2NSLiLleQMy1EdHrzyN6/TlEnc8g6nwa0ckKRCafQsTxJCKOJxBxbEBk4nGEJ9YjbH8UYfs6hG0PI2wrR8j6EELWNQhZVyM0vgqh8ZUIjpUhOFaKoEWHoKUYwVEtAqNFEMyaWcFccCUwrF67OLzH4IXHALnAB8waBMyFCJjzIQyrvT6LqkRcwG0wyRU+MKKGMKKCMJzbwxHQz8gcHv6h7GlxAQZ+5eX3/ilTIx4k1duAsp4GlF16F6UX96L0wl7oLrwD3fl6lJyrR8nZOhR318ZL+2MNtF17oP1hD4rOvB2vFZ27seL0LmhO7YLm5FsoPFEdrwLjmyjo2In89p3Ib3sD6rYdUB/fAdWx7VB9v30OXhjOgTCUBc4vQK+8HOBVra9T8MJQJk+Abhs5wOe1VlHw/sEMcQG25+UAn9dSRcH7B9N4AvQFKwf43KOvUfD+QSVPgL7byAE+98g2Ct5/7QGOAHOrlAN8znfbKHj/tft5AvR9PlF43ZkaHHNexZ++2/jDdxutkz+h+OTuhOBzml+l4P0D94kLsA+pRFeewLNpcfQlBJ/dXEnB+wbu5QnQT9hE24asPJvf73gTgs/+tpKC9/XfwxFgtgeJ9jxpGza/zd5KCD7r8CsUvK9fwROg9zY8+A19n6B+woh6uxGPmT6iLtgWx5V5AkfsJgp+3bkPUGfriFd59/ui8FnfbKXg+QLMxoy38gS8ydUVrzq7kbrbkAuW9DxpG1IEvqi9mlp5At707/trrR2i8JmHtlLwXAF2V8lrG1ZA6q1ynkDzwvCZhwwUPF+A2RLzep60DZEg8Ot7983BP3vxY7Q7f4bHexMzQV+83Ldu4LjjKiq69831PGmbWmtHvMq7GkXhMw5KEqD381IeUoXGahy29SAUjUAs5LWDlvPIO1rF7fnM/8BnHNBLEaAPIwvBP3LpQ1QOHECtrQONzs54kb9NN0ex1PTeMKPG2h5/b4OzEzXWNuh//RyrTtXNg0//eosEAeYkxcJv/OWzub5lS4iGlixA/lfscypMn1Lw6fslCLDHQLZtyIqJfbHUiH1Ow+RpCj5t/2YJAswZlu15sS9dToEmVxcFn/bVy1IE6AP4cu8ql3LBpjPwaV9KEWCmB3KAV36xSYoAPfqQA7xSigA7t5EDvFKaAD10kgO8UpoAPTGTA7xSkgAz7ksG/GJR3p0APatMxsonVSAwVjojZdDKTszY6QF7kiIV6lcAnAoxsAvUX6ICQYuuL5nwyyFxp19hEv8FxrVrAxatN5nwdynhDQwo1ih48VmKS4TRoh5hRDOdLPgEJKbJyi8Kn0oqqaSi+L/zN7ZemOeX1vkBAAAAAElFTkSuQmCC";

  if (type === "Google") {
    return (
      <button
        disabled={disalbed}
        type="button"
        className={`login-with-google-btn btn-block ${
          disalbed ? "disalbed" : ""
        }`}
        onClick={fun}
      >
        <img src={google_icon} className="google_icon" alt="Google Logo" />
        {txt} with {type}
      </button>
    );
  }

  return (
    <button
      disabled={disalbed}
      type="button"
      className={`login-with-google-btn btn-block ${
        disalbed ? "disalbed" : ""
      }`}
      onClick={fun}
    >
      <img
        src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/canvaslogo.png"
        alt="Schoology Logo"
      />
      {/* <img src={CleverLogo} alt="Clever Logo" /> */}
      <img src={google_classroom_icon} alt="Google Classroom Logo" />
      <img src={SchoologyLogo} alt="Schoology Logo" />
      {type === "Edlink" ? `${txt} with your LMS` : `${txt} with ${type}`}
    </button>
  );
};

function renderSize(size: "small" | "normal" | "large") {
  switch (size) {
    case "small":
      return "btn_sm";
    case "large":
      return "btn_lg";
    default:
      return "";
  }
}
