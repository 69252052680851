import React from "react";
import { isMobile } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoadingTable = () => {
  const columns = [
    {
      name: "NAME",
      id: "last_name",
    },
    {
      name: "LOGIN CODE",
      id: "login_code",
      xs_hidden: true,
    },
    {
      name: "CLASS PERIOD",
      id: "class_id",
      xs_hidden: true,
    },
    {
      name: "LOGGED",
      id: "minutes",
    },
    {
      name: "LAST SEEN",
      id: "last_login",
      xs_hidden: true,
    },
  ];
  return (
    <div className="table bg-white table-hover w-100">
      <table className="loading-table">
        <thead>
          <tr>
            {columns.map((column) => {
              if (column.xs_hidden && isMobile) {
                return null;
              }
              return (
                <th
                  key={column.id}
                  style={{
                    width: "20%",
                  }}
                  className={column.xs_hidden ? "hidden_xs" : ""}
                >
                  {column.name}
                </th>
              );
            })}
            <th className="text-center d-none-portrait">{"ACTIONS"}</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(4)].map((_, index) => (
            <tr key={`squeleton_row${index}`}>
              {columns.map((c, index) => {
                if (c.xs_hidden && isMobile) {
                  return null;
                }
                return (
                  <td key={`${c.id}_skelletontd_${index}`}>
                    <Skeleton width={"100%"} />
                  </td>
                );
              })}
              <td className={isMobile ? "d-none" : ""}>
                <Skeleton width={"100%"} height={40} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LoadingTable;
