import React from "react";
import { Link } from "react-router-dom";
import { getUserImage } from "../../middleware/common-functions";

import nav_side from "./nav_side.json";

const MobNav: React.FC = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return (
    <div className="mob_nav">
      <Link to="/" className={window.location.pathname === "/" ? "active" : ""}>
        <i className="far fa-home-alt" aria-hidden="true"></i>
      </Link>
      {nav_side.map((item) => {
        if (item.mob_show !== true) return null;
        if (item.teacher_only && !user.is_teacher) return null;
        if (item.student_only && user.is_teacher) return null;
        if (item.admin && !user.admin) return null;
        const active = window.location.pathname === item.to ? "active" : "";

        return (
          <Link
            key={`mob_nav${item.to}`}
            className={`btn btn-link ${active}`}
            to={item.to}
            id={`mob_nav${item.to.split("/").join("_")}`}
          >
            <i className={item.icon} aria-hidden="true"></i>
          </Link>
        );
      })}
      <Link to="/navigation">
        <img
          src={
            user.profile_pic
              ? getUserImage(user.profile_pic)
              : "https://cdn.flangoo.com/assets/global/thumb1.png"
          }
          className="profile_img"
          alt="User Profile"
        />{" "}
      </Link>
    </div>
  );
};

export default MobNav;
