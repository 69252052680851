import React, { useEffect, useState } from "react";
import { Form, InputGroup, Nav } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import FileDownload from "js-file-download";

import { CatItem, Language, Level, ReaderImages } from "../../../models/reader";
import axios_api from "../../../middleware/axios_api";
import { IconJumbotron } from "../../common/icons";
import { BuyNowButton } from "../homepage/hero";
import { GET_READERS } from "./queries";
import TitleBlock from "./title_block";
import { Helmet } from "react-helmet";
import { ErrorType } from "../../../models/errors";

export type BooksTitlesType = {
  coming_soon: boolean;
  title: string;
  author?: string;
  description_short: string;
  themes?: string;
  level_id: string;
  image_ver: number;
  images: ReaderImages;
  level: Level;
  language: Language;
  categories: [CatItem];
};

const TitlesPage: React.FC = () => {
  const [books, setBooks] = useState<BooksTitlesType[]>([]);
  const [loadingState, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType | false>(false);
  const [activeLang, setActiveLang] = useState<"Spanish" | "French" | "German">(
    "Spanish"
  );
  const [searchTerm, setSearchTerm] = useState("");
  const { data: booksDB } = useQuery<{ booksTitles: BooksTitlesType[] }>(
    GET_READERS
  );
  const [langs, setLangs] = useState<Language[]>([]);

  useEffect(() => {
    if (booksDB) {
      const set_langs: any = {};
      booksDB.booksTitles.forEach((b) => {
        if (!set_langs[b.language.id]) {
          set_langs[b.language.id] = b.language;
        }
      });
      setLangs(Object.values(set_langs));
      setBooks(booksDB.booksTitles);
    }
  }, [booksDB]);

  useEffect(() => {
    if (searchTerm !== "") {
      filterBooks(searchTerm);
    } else {
      setBooks(booksDB?.booksTitles || []);
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  const filterBooks = (search_termn: string) => {
    if (booksDB === undefined || search_termn.length < 3) {
      return;
    }
    const filtered = booksDB.booksTitles.filter(
      (book) =>
        book.title.toLowerCase().includes(search_termn.toLowerCase()) ||
        book.author?.toLowerCase().includes(search_termn.toLowerCase()) ||
        book.themes?.toLowerCase().includes(search_termn.toLowerCase())
    );

    setBooks(filtered);
  };

  const handleSelectLang = (lang: string) => {
    setActiveLang(lang as "Spanish" | "French" | "German");
  };

  const downloadLibrary = async () => {
    if (loadingState) {
      return;
    }
    setLoading(true);
    setError(false);
    try {
      const lang_id = langs.find((lang) => lang.name === activeLang)?.id;
      const response = await axios_api.get(`books/download/${lang_id}`);
      setLoading(false);
      const file_name = `Flangoo Library ${activeLang}.csv`;
      FileDownload(response.data, file_name);
    } catch (catch_error: any) {
      setLoading(false);
      if (catch_error?.response?.data?.message) {
        setError({
          message: `Please refresh the page and try again. Error: ${catch_error.response.data.message}`,
        });
      } else {
        setError({
          message: "Please refresh the page and try again",
        });
      }
    }
  };

  return (
    <div className="titles_page front_face">
      <Helmet title="A Library of Spanish, French, and German Readers!" />
      <div
        className="hero"
        style={{
          backgroundImage: `url('${process.env.REACT_APP_CDN_IMG}flangoo-bg-books-image.png?v=1')`,
        }}
      ></div>
      <div className="container titles">
        <h1>
          A Library of Spanish, French, and German Readers, with More on the
          Way!
        </h1>
        <p>
          Best-selling readers from authors Mira Canion, Jennifer Degenhardt,
          Deb Navarre, Tom Alsop, Fabiola Canale, and many more. Flangoo also
          features Acquisition™ Readers with comprehension questions, and
          Enhanced™ Readers featuring audio, video, cultural notes, and
          comprehension questions.
        </p>
        {error ? (
          <div className="alert alert-warning d-flex align-items-center">
            <div>
              <i
                className="fal fa-exclamation-square fa-3x mr-3"
                aria-hidden="true"
              ></i>
            </div>
            <div>
              <h4>Something went wrong</h4>
              <p>{error.message}</p>
            </div>
          </div>
        ) : null}
        <InputGroup className="mb-3 w-25">
          <InputGroup.Text
            id="basic-addon1"
            style={{ borderRadius: "5px 0 0 5px" }}
          >
            <i className="fas fa-search" aria-hidden="true"></i>{" "}
          </InputGroup.Text>
          <Form.Control
            placeholder="Search for a title or author..."
            aria-label="Search for a title or author..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-describedby="search by book title"
          />
        </InputGroup>

        {langs.length ? (
          <Nav variant="tabs" defaultActiveKey="tabs_id_1">
            {langs.map((lang) => (
              <Nav.Item key={`tabs_names_${lang.id}`}>
                <Nav.Link
                  eventKey={`tabs_id_${lang.id}`}
                  onClick={() => handleSelectLang(lang.name)}
                >
                  <RenderFlag lang={lang.name} /> {lang.name} Readers
                </Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item className="ml-5">
              <Nav.Link
                eventKey="tabs_id_4"
                onClick={() => downloadLibrary()}
                className="text-muted"
              >
                <i
                  className={`${
                    loadingState
                      ? "fad fa-spinner-third fa-spin"
                      : "fas fa-download"
                  }  mr-2`}
                ></i>
                Download {activeLang} Library
              </Nav.Link>
            </Nav.Item>
          </Nav>
        ) : null}
        {langs.map((lang) => (
          <TitlesRow
            key={`title_Row${lang.id}`}
            lang={lang}
            titles={books.filter((b) => b.language.id === lang.id)}
            show={lang.name === activeLang}
          />
        ))}
      </div>
      <BuyNowButton main_cta={"Try It Free"} />
    </div>
  );
};

type TitlesRowProps = {
  lang: Language;
  titles: BooksTitlesType[];
  show: boolean;
};

const TitlesRow: React.FC<TitlesRowProps> = ({ lang, titles, show }) => (
  <div className={`books_container py-3 ${show ? "" : "d-none"}`}>
    <div className="books">
      {titles.length > 0 ? (
        titles.map((t, key) => (
          <TitleBlock key={`title_item${t.title}_${key}`} bookTitle={t} />
        ))
      ) : (
        <IconJumbotron
          icon="fas fa-book-open"
          classes="text-muted opacity_4"
          txt="No titles found"
          txt_classes="text-dark"
          help_txt_classes="text-dark"
          help_text="We are adding more readers to our library. Check back soon for more titles!"
        />
      )}
    </div>
  </div>
);

type RenderFlagProps = {
  lang: string;
};
const RenderFlag: React.FC<RenderFlagProps> = ({ lang }) => {
  switch (lang) {
    case "Spanish":
      return <span>🇪🇸</span>;
    case "French":
      return <span>🇫🇷</span>;
    case "German":
      return <span>🇩🇪</span>;
    default:
      return <span>-</span>;
  }
};
export default TitlesPage;
