import { gql } from "@apollo/client";

export const GET_PLANS = gql`
  query {
    plans(all: false) {
      id
      name
      price_amount
      regular_price
      loyalty_price
      subtitle
      students
      days
      sku
    }
    pageSettings(types: ["paypal"]) {
      id
      type
      description
    }
  }
`;

export const PAYMUTATION = gql`
  mutation paymentSuccess(
    $pay_id: ID!
    $plan_id: ID!
    $method: String!
    $promo_code: String
  ) {
    paymentSuccess(
      pay_id: $pay_id
      plan_id: $plan_id
      method: $method
      promo_code: $promo_code
    ) {
      id
      plan_id
      status
      from
      to
      payment_method
      name
    }
  }
`;

export const GETPROMOCODE = gql`
  query getPromoCode($promo_code: String!) {
    promoCode(promo_code: $promo_code) {
      id
      code
      name
      value
      valid_thru
      type
    }
  }
`;
