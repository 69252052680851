import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import SearchInput from "./search";

import nav_data from "./nav.json";
import nav_side from "./nav_side.json";
import Notifications from "./notifications";
import { getUserImage } from "../../middleware/common-functions";
import { DataContext } from "../../context/data-context";
import MobNav from "./mobNav";
import { isMobile } from "react-device-detect";
import { UserItem } from "../../models/user";

const hide_nav = ["reader", "thankyou"];

export const Header: React.FC = () => {
  const dataContext = useContext(DataContext);
  const user: UserItem = JSON.parse(localStorage.getItem("user") || "{}");
  const show_banner = user.is_teacher && user.plan?.plan_id === "2";
  const settings =
    dataContext.user && dataContext.user.settings
      ? dataContext.user.settings
      : {
          hide_achievements: false,
          theme: false,
        };

  const [show_header, setshow_header] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const page = location.pathname.split("/")[1];

      if (hide_nav.find((i) => i === page)) {
        setshow_header(false);
      } else if (!show_header) {
        setshow_header(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!show_header) {
    return null;
  }

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className="hidden-print navbar nav_dark navbar-expand-lg navbar-dark main_nav">
      <Link to="/" className="navbar-brand">
        <img
          src={`https://flangoo-cdn.s3.us-east-2.amazonaws.com/${
            settings.theme && settings.theme === "green_theme"
              ? "flangoo_logo_512.png"
              : "Flangoo_logo_white512.png"
          }`}
          alt="nav logo"
        />
      </Link>
      {isMobile ? (
        <>
          <div className="d-flex">
            <Notifications />
            <NavbarToggler onClick={toggle} />
          </div>
        </>
      ) : null}

      <Collapse isOpen={isOpen} navbar>
        {isMobile ? null : <NavbarToggler onClick={toggle} />}
        <Nav navbar className=" w-100">
          {nav_data.map((item) => {
            if (item.teacher_only && !user.is_teacher) return null;
            if (!item.to) return null;
            return (
              <NavItem
                className="nav-item  align-items-center d-flex"
                onClick={toggle}
                key={`nav_item_${item.to}`}
              >
                <Link className="nav-link" to={item.to}>
                  {item.txt}
                </Link>
              </NavItem>
            );
          })}
          {!user.is_teacher || user.plan?.plan_id === "2" ? null : (
            <NavItem
              className={`nav-item  align-items-center d-flex `}
              onClick={toggle}
            >
              <a
                className="nav-link text_gold"
                href="https://shop.flangoo.com"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#ffdf00" }}
              >
                Member Store{" "}
                <i
                  className="far fa-sparkles"
                  style={{
                    position: "absolute",
                    left: "91%",
                    top: "50%",
                    transform: "translateY(-80%)",
                  }}
                ></i>
              </a>
            </NavItem>
          )}

          <SearchInput />
          <Notifications className="xs_hidden" />
          <UncontrolledDropdown className="nav-item dropdown mr-2 xs_hidden">
            <DropdownToggle nav caret id="user_nav">
              <img
                src={
                  user.profile_pic
                    ? getUserImage(user.profile_pic)
                    : "https://cdn.flangoo.com/assets/global/thumb1.png"
                }
                className="profile_img"
                alt="User Profile"
              />{" "}
              {user.name}
            </DropdownToggle>
            <DropdownMenu right>
              {nav_side.map((item) => {
                if (item.teacher_only && !user.is_teacher) return null;
                if (item.student_only && user.is_teacher) return null;
                if (item.admin && !user.admin) return null;
                if (!user.is_teacher) {
                  if (
                    item.priv &&
                    settings.hide_achievements &&
                    settings.hide_achievements === true
                  )
                    return null;
                }
                if (item.type === "link") {
                  return (
                    <DropdownItem key={`nav_side_${item.to}`}>
                      <a
                        className="nav-link"
                        href={item.to}
                        target="_blank"
                        id={`nav_item${item.to.split("/").join("_")}`}
                        rel="noreferrer"
                      >
                        {item.txt}
                      </a>
                    </DropdownItem>
                  );
                }
                return (
                  <DropdownItem key={`nav_side_${item.to}`}>
                    <Link
                      className="nav-link"
                      to={item.to}
                      onClick={toggle}
                      id={`nav_item_${item.to.split("/").join("_")}`}
                    >
                      {item.txt}
                    </Link>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
      {show_banner ? (
        <div className="giveaway_nav">
          <Link to="/help" className="btn btn-danger btn-sm">
            <i className="fal fa-gift" aria-hidden="true"></i> Schedule a
            Walkthrough—Get 30 More Days
          </Link>
        </div>
      ) : null}
    </Navbar>
  );
};

export const Footer = () => {
  const [show_footer, setshow_footer] = useState<boolean | "live">(true);
  const location = useLocation();
  const date = new Date();
  const year = date.getFullYear();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    if (location) {
      const page = location.pathname.split("/")[1];
      if (page === "live") {
        setshow_footer("live");
      } else if (hide_nav.find((i) => i === page)) {
        setshow_footer(false);
      } else if (show_footer !== true) {
        setshow_footer(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!show_footer) {
    return null;
  }
  if (show_footer === "live") {
    return (
      <footer className="hidden-print ">
        <div className="container ">
          <div className="row justify-content-end">
            <div className="col-md-6">
              <img
                src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/Flangoo_logo_white512.png"
                className="fb_footer ml-3"
                alt="Flangoo logo"
              />
              <img
                src="https://cdn.flangoo.com/assets/global/td-logo.png"
                className="fb_footer ml-3"
                alt="Teacher's discovery logo"
              />
              <img
                src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/mex_logo.png"
                className="fb_footer ml-3"
                alt="Mexico logo"
              />
            </div>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="hidden-print ">
      <MobNav />
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <img
              src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/Flangoo_logo_white120.png"
              alt="Logo"
              className="ftr-logo"
            />
            <ul className="list-inline">
              <li className="list-inline-item mr-4">
                <Link to={`/faq`}>FAQ</Link>
              </li>
              <li className="list-inline-item mr-4">
                <Link to={`/general/privacypolicy`}>Privacy Policy</Link>
              </li>
              <li className="list-inline-item mr-4">
                <Link to={`/general/refundpolicy`}>Refund Policy</Link>
              </li>
              {user.is_teacher ? (
                <li className="list-inline-item mr-4">
                  <Link to="/refer_friend">Refer a Friend</Link>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="col-lg-5">
            <a
              href="https://www.facebook.com/Go2Flangoo/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="https://cdn.flangoo.com/assets/global/facebook_nav.png"
                className="fb_footer"
                alt="Facbook logo"
              />
            </a>
            <a
              href="https://www.teachersdiscovery.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/td.png"
                alt="Teacher's discovery logo"
                style={{ width: "157px", height: "75px" }}
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="ftr-copy">
              <p>
                Copyright © 2005-{year} Teacher's Discovery®, a division of
                American Eagle Co., Inc. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
