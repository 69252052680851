import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NumericType, StringType, toDate } from "../../../middleware/dates";
import { AssignmentItem } from "../../../models/assignment";
import Singleassignment from "../../common/assignment";

type assignmentProps = {
  assignments: [AssignmentItem];
};
const Assignment: React.FC<assignmentProps> = ({ assignments }) => {
  const [first_assignment, setFirstAssignment] = useState<AssignmentItem>();

  useEffect(() => {
    setFirstAssignment(
      assignments[Math.floor(Math.random() * assignments.length)]
    );
  }, [assignments]);

  if (!first_assignment) {
    return null;
  }
  if (assignments.length === 1) {
    return <Singleassignment assignment={assignments[0]} />;
  }

  const title = `You have ${assignments.length} active assignments `;
  return (
    <div
      className="assignment_cont"
      style={{
        backgroundImage: `url(${first_assignment.book!.images?.poster})`,
      }}
    >
      <div className="container_info">
        <h4>{title}</h4>

        <div className="books">
          {assignments.map((assignment) => (
            <div className="mb-2">
              <div className="row">
                <div className="col-3">
                  <img
                    src={assignment.book!.images.cover}
                    alt="book cover"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="col-9">
                  <h6 className="">{assignment.title} </h6>
                  <span className="badge pl-0">
                    Due:{" "}
                    {toDate(assignment.end_time, {
                      day: NumericType.Digit,
                      month: StringType.Long,
                    })}
                  </span>
                  <p className="p-0 pb-0">{assignment.message}</p>
                  <Link
                    to={`/reader/${assignment.book!.url}/${
                      assignment.book!.id
                    }`}
                    className="btn btn-outline-light btn_round green_outline_gray"
                  >
                    Go to Reader
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Assignment;
