import React, { useEffect, useState, MouseEvent } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { GET_PLANS } from "./queries";
import { PlanType } from "../../../models/plans";
import { LoaderBar } from "../../../middleware/loaders";
import Plans from "./plans";
import Payment from "./payment";
import { PageSettingItem } from "../../../models/pageSettings";
import { LoaderDots } from "../../../middleware/main_loader";
import PromoCode from "./promo_code";
import { PromoCodeType } from "../../../models/promocodes";

type Views = "plans" | "payment";

const PurchasePlan: React.FC = () => {
  const { data, loading } = useQuery<{
    plans: PlanType[];
    pageSettings: PageSettingItem[];
  }>(GET_PLANS, { fetchPolicy: "no-cache" });
  const [plans, setPlans] = useState<PlanType[]>([]);
  const [promo_code, setPromoCode] = useState<PromoCodeType | undefined>();
  const [selectedPlan, setPlan] = useState<PlanType | undefined>(undefined);
  const [view, setView] = useState<Views>("plans");
  const [is_loyalty, setLoyalty] = useState<boolean>(false);
  useEffect(() => {
    const document_body = document.getElementsByTagName("body")[0];
    if (document_body) {
      document_body.classList.add("white_bg");
    }
    return () => {
      document_body.classList.remove("white_bg");
    };
  }, []);
  console.log("index.tsx:35 | plans", plans);
  useEffect(() => {
    if (data?.plans) {
      ///search for loyalty_pricing inside the plans
      const loyalty = data.plans.find((p) => p.loyalty_price);
      if (loyalty) {
        setLoyalty(true);
      }
      setPlans(data.plans);
    }
  }, [data]);

  const handlePlanSelect = (ev: MouseEvent<HTMLButtonElement>) => {
    const plan_id = ev.currentTarget.getAttribute("data-plan-id");
    if (!plan_id) return;
    const plan = plans.find((p) => p.id === plan_id);
    if (!plan) return;
    setPlan(plan);
  };

  const handlePageView = (ev: MouseEvent<HTMLButtonElement>) => {
    const set_view = ev.currentTarget.getAttribute("data-view") as Views;
    if (!set_view) return;
    setView(set_view);
  };

  if (loading) {
    return (
      <main className="first-container bg-light py-5 text-center ">
        <LoaderBar fixed={true} />
        <LoaderDots size={20} />
        <p className="mt-3 text-muted">Getting Plans</p>
      </main>
    );
  }
  let title_txt = "Purchase Your Flangoo Membership";
  if (is_loyalty) {
    title_txt = "Renew Your Membership Today with Loyalty Pricing";
  }

  const onApplyPromoCode = (promo_Code: PromoCodeType) => {
    ///update plans according to promo code
    const new_plans = plans.map((plan) => {
      if (promo_Code.type === "discount_cash" && plan.id !== "1") {
        let loyalty_price = plan.loyalty_price ? plan.loyalty_price : 0;
        let regular_price =
          plan.regular_price > 1 ? plan.regular_price : plan.price_amount;
        let new_price = plan.price_amount - parseFloat(promo_Code.value);
        ///set price to two decimals
        new_price = Math.round(new_price * 100) / 100;
        if (plan.loyalty_price) {
          loyalty_price = plan.loyalty_price - parseFloat(promo_Code.value);
          loyalty_price = Math.round(loyalty_price * 100) / 100;
        }
        return {
          ...plan,
          price_amount: new_price,
          regular_price,
          loyalty_price,
          has_promo: true,
        };
      }
      return plan;
    });
    setPromoCode(promo_Code);
    setPlans(new_plans);
  };

  const removeAppliedCode = () => {
    if (!data || !data.plans) {
      return;
    }
    setPromoCode(undefined);
    setPlans(data.plans);
  };

  return (
    <main className="first-container bg-light py-5">
      <div className="container">
        <h3 className="black_text">{title_txt}</h3>
        <hr />
        <div className="row justify-content-between">
          {view === "plans" && plans ? (
            <Plans
              plans={plans}
              selectedPlan={selectedPlan}
              handlePlanSelect={handlePlanSelect}
              is_loyalty={is_loyalty}
            />
          ) : null}
          {view === "payment" && selectedPlan ? (
            <Payment
              selectedPlan={selectedPlan}
              paypal_setting={data?.pageSettings[0]}
              handlePageView={handlePageView}
              promo_code={promo_code}
            />
          ) : null}
          <div className="col-md-10 d-flex justify-content-between align-items-center">
            <Link
              to="/youraccount"
              className="btn btn-dark text-white green_contain_gray"
            >
              <i className="fas fa-angle-left" aria-hidden="true"></i> Go Back
            </Link>
            {view === "plans" ? (
              <PromoCode
                onApplyPromoCode={onApplyPromoCode}
                isHorizontal={true}
                removeAppliedCode={removeAppliedCode}
              />
            ) : null}
            {view === "plans" ? (
              <button
                onClick={handlePageView}
                data-view="payment"
                className="btn btn-outline-danger  btn_lg green_contain_green"
                disabled={selectedPlan ? false : true}
              >
                <i className="fab fa-paypal" aria-hidden="true"></i> Pay Now
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </main>
  );
};

export default PurchasePlan;
